<template>
  <div id="app">
    <app-header></app-header>
    <router-view></router-view>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    appHeader: Header,
    appFooter: Footer,
  },
};
</script>

<style lang="scss">
// Layout
.outer-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}
.one-third-width {
  width: 33%;
}
.two-thirds-width {
  width: 67%;
}
@media all and (max-width: 700px) {
  .outer-box {
    display: block;
  }
  .half-width,
  .one-third-width,
  .two-thirds-width {
    width: 100%;
  }
}
.fix-height {
  height: 800px;
}
@media all and (max-width: 960px) {
  .fix-height {
    height: 600px;
  }
}
@media all and (max-width: 700px) {
  .fix-height {
    height: 400px;
  }
}
.vertical-space {
  margin-top: 60px;
}
.inner-space {
  padding: 0 40px 0 40px;
}
.inner-space-all {
  padding: 40px;
}
.text-space {
  padding-top: 20px;
}

.bg-blue {
  background-color: #5693ce;
}
.bg-mushroom {
  background-color: #f6f3ed;
}

.relative {
  position: relative;
}

.overlay-text {
  position: absolute;
  bottom: 60px;
  left: 30px;
  color: #000;
  font-size: 26px;
  padding: 10px;
  background-color: rgba(250,250,250, 0.8);
}

// Images
img {
  width: 100%;
}

// Typography
html,body,h1,h2,h3,h4,h5,h6,p,ul,ol,li,span {
  font-family: 'quicksand';
  color: #7C7C7C;
}

p {
  margin-bottom: 0;
}

h1 {
  text-transform: uppercase;
  font-size: 22px;
}

h2 {
  text-transform: uppercase;
  font-size: 14px;
}

.large-h2 {
  font-size: 22px;
}

p.small {
  font-size: 14px;
}

p.large {
  font-size: 22px;
}
p.xl {
  font-size: 24px;
}
@media all and (max-width: 960px) {
  p.xl {
    font-size: 18px;
  }
}

.text-white {
  color: #FFF;
}

@media all and (max-width:700px) {
  .text-center-small {
    text-align: center;
  }
}
.prominent-p {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.artist {
  text-transform: uppercase;
  font-size: 32px;
}
.exhibition {
  text-transform: uppercase;
  font-size: 16px;
}
.intro {
  font-style: italic;
}
.art-info {
  text-align: center;
}
.artist {
  font-size: 28px;
  text-transform: uppercase;
}
.title {
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 14px;
}
.medium {
  padding-top: 30px;
  font-size: 14px;
}
.size {
  padding-top: 6px;
  font-size: 13px;
}

// Padding, pa = pad all, pl = pad left, pr = pad right, pt = pad tp, pb = pad bottom
.pa-1 { padding: 4px }
.pa-0 { padding: 0 }
.pa-2 { padding: 8px }
.pa-3 { padding: 12px }
.pa-4 { padding: 16px }
.pa-5 { padding: 20px }
.pa-10 { padding: 40px }
.pa-20 { padding: 80px }
.pa-30 { padding: 120px }
.pt-0 { padding-top: 0 }
.pt-1 { padding-top: 4px }
.pt-2 { padding-top: 8px }
.pt-3 { padding-top: 12px }
.pt-4 { padding-top: 16px }
.pt-5 { padding-top: 20px }
.pt-7 { padding-top: 28px }
.pt-10 { padding-top: 40px }
.pt-20 { padding-top: 80px }
.pt-30 { padding-top: 120px }
.pt-40 { padding-top: 120px }
.pt-50 { padding-top: 120px }
.pb-0 { padding-bottom: 0 }
.pb-1 { padding-bottom: 4px }
.pb-2 { padding-bottom: 8px }
.pb-3 { padding-bottom: 12px }
.pb-4 { padding-bottom: 16px }
.pb-5 { padding-bottom: 20px }
.pb-10 { padding-bottom: 40px }
.pb-20 { padding-bottom: 80px }
.pb-30 { padding-bottom: 120px }
.pr-0 { padding-right: 0 }
.pr-1 { padding-right: 4px }
.pr-2 { padding-right: 8px }
.pr-3 { padding-right: 12px }
.pr-4 { padding-right: 16px }
.pr-5 { padding-right: 20px }
.pr-10 { padding-right: 40px }
.pr-20 { padding-right: 80px }
.pr-30 { padding-right: 120px }
.pl-0 { padding-left: 0 }
.pl-1 { padding-left: 4px }
.pl-2 { padding-left: 8px }
.pl-3 { padding-left: 12px }
.pl-4 { padding-left: 16px }
.pl-5 { padding-left: 20px }
.pl-10 { padding-left: 40px }
.pl-20 { padding-left: 80px }
.pl-30 { padding-left: 120px }

@media all and (max-width: 700px) {
  .pt-5-small {
    padding-top: 20px;
  }
  .pb-5-small {
    padding-bottom: 20px;
  }
}

// Margins
.ma-0 { margin: 0 }
.ma-1 { margin: 4px }
.ma-2 { margin: 8px }
.ma-3 { margin: 12px }
.ma-4 { margin: 16px }
.ma-5 { margin: 20px }
.ma-10 { margin: 40px }
.ma-20 { margin: 80px }
.ma-30 { margin: 120px }
.mt-0 { margin-top: 0 }
.mt-1 { margin-top: 4px }
.mt-2 { margin-top: 8px }
.mt-3 { margin-top: 12px }
.mt-4 { margin-top: 16px }
.mt-5 { margin-top: 20px }
.mt-10 { margin-top: 40px }
.mt-20 { margin-top: 80px }
.mt-30 { margin-top: 120px }
.mb-0 { margin-bottom: 0 }
.mtb-2 { margin-bottom: 8px }
.mb-3 { margin-bottom: 12px }
.mb-4 { margin-bottom: 16px }
.mb-5 { margin-bottom: 20px }
.mb-10 { margin-bottom: 40px }
.mb-20 { margin-bottom: 80px }
.mb-30 { margin-bottom: 120px }
.ml-0 { margin-left: 0 }
.ml-1 { margin-left: 4px }
.ml-2 { margin-left: 8px }
.ml-3 { margin-left: 12px }
.ml-4 { margin-left: 16px }
.ml-5 { margin-left: 20px }
.ml-10 { margin-left: 40px }
.ml-20 { margin-left: 80px }
.ml-30 { margin-left: 120px }
.mr-0 { margin-right: 0 }
.mr-1 { margin-right: 4px }
.mr-2 { margin-right: 8px }
.mr-3 { margin-right: 12px }
.mr-4 { margin-right: 16px }
.mr-5 { margin-right: 20px }
.mr-10 { margin-right: 40px }
.mr-20 { margin-right: 80px }
.mr-30 { margin-right: 120px }

// Backgrounds

// Buttons
.button {
  background-color: #7C7C7C;
  color: #FFF!important;
  margin-bottom: 0;

  &:hover,
  &:focus {
    background-color: #333;
  }
}

// Room styles
.pan-zoom-container {
  position: absolute;
  background-color: #333;
  width: 100%;
  overflow: hidden;
}
.pan-zoom-container img {
  width: 137%;
  max-width: 137%;
  animation: slide 35s infinite;
  display: block;
}
@keyframes slide {
  0% {
    transform: scale3d(1, 1, 1) translate3d(-27%, 0px, 0px);
    animation-timing-function: linear;
  }
  50% {
    transform: scale3d(1, 1, 1) translate3d(0px, 0px, 0px);
    animation-timing-function: linear;
  }
  100% {
    transform: scale3d(1, 1, 1) translate3d(-27%, 0px, 0px);
    animation-timing-function: linear;
  }
}
.bounceup {
  opacity: 0;
  margin-top: 160px;
  transition:
    margin-top 1400ms cubic-bezier(.18, 1, .21, 1),
    opacity 4000ms cubic-bezier(.18, 1, .21, 1);
}
.visible {
  opacity: 1;
  margin-top: 60px;
}
.border-right {
  border-right: 1px solid #CCC;
}
// Uploader
.uppy-Root {
  margin-top: 20px;
  border: 1px solid #CCC;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
}
.uppy-Dashboard-dropFilesHereHint {
  display: none;
}
.uppy-Dashboard-Item-preview {
  width: 100px;
  float: left;
  margin-right: 10px;
}
.uppy-Dashboard-Item-fileInfoAndButtons {
  display: none;
}
.uppy-c-btn {
  background-color: #C7C7C7;
  color: #FFF;
  padding: 20px;
  &:hover {
    background-color: #333;
  }
}
.uppy-DashboardContent-back {
  color: #FF0000;
}
.uppy-Dashboard-browse {
  cursor: pointer;
}
.uppy-c-icon {
  display: none;
}
.uppy-StatusBar-status {
  display: none;
}

// Inputs
select,
[type="color"],
[type="email"],
[type="number"],
[type="password"],
[type="text"],
textarea {
  margin-bottom: 3px;
}
.settings {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    background-color: rgba(0,0,0,0.5);
    padding: 5px;
    border: 1px solid #333;
    border-radius: 5px;
    span {
      color: #000;
      display: inline-block;
      margin: 0 3px;
      cursor: pointer;

      img {
        width: 26px;
      }

      &:hover,
      &:focus {
        background-color: rgba(0,0,0,0.9);
      }
    }
  }

  .iiz {
    display: block!important;
  }
</style>
