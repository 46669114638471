<template>
  <div class="outer">

    <div class="outer-box pt-5">
      <div class="two-thirds-width inner-space">
        <h1>Viewing Rooms</h1>
      </div>
      <div class="one-third-width inner-space">&nbsp;</div>
    </div>

    <div class="outer-box pt-5">
      <div class="two-thirds-width inner-space">
        <h2>Current</h2>
      </div>
      <div class="one-third-width inner-space">&nbsp;</div>
    </div>

    <div class="outer-box pt-5">
      <div class="two-thirds-width inner-space">
        <img
          class="contents-image"
          :src="viewingRooms[0].uri"
          alt="">
      </div>
      <div class="one-third-width inner-space text-center">
        <div class="artist">{{ viewingRooms[0].title }}</div>
        <div class="pt-7">
          <button
            class="button"
            @click="goToRoom(0)"
          >Launch Viewing Room</button>
        </div>
      </div>
    </div>

    <div class="outer-box pt-5">
      <div class="two-thirds-width inner-space">
        <h2>Previous</h2>
      </div>
      <div class="one-third-width inner-space">&nbsp;</div>
    </div>

    <div
      v-for="(room, index) in viewingRooms"
      :key="index">
      <div
        v-if="index > 0"
        class="outer-box pt-5">
        <div class="two-thirds-width inner-space">
          <img
            class="contents-image"
            :src="room.uri"
            alt="">
        </div>
        <div class="one-third-width inner-space text-center">
          <div class="artist">{{ room.title }}</div>
          <div class="pt-7">
            <button
              class="button"
              @click="goToRoom(index)"
            >Launch Viewing Room</button>
          </div>
        </div>
      </div>
    </div>
    <app-form :room="selectedRoom" v-if="emailForm" />
  </div>
</template>

<script>
import accessForm from '@/components/AccessForm.vue';
import axios from '../axios';

export default {
  name: 'Home',
  components: {
    appForm: accessForm,
  },
  data() {
    return {
      emailForm: false,
      selectedRoom: '',
      viewingRooms: [],
    };
  },
  methods: {
    getViewingRooms() {
      axios.get('/viewingRooms/getPublic.json')
        .then((response) => {
          this.viewingRooms = response.data;
        });
    },
    goToRoom(room) {
      this.selectedRoom = this.viewingRooms[room];
      const access = this.$cookies.get('sdlAccess');
      if (access === 'true') {
        this.$store.commit('grantAccess');
        this.$router.push(`/room/${this.selectedRoom.id}`);
      } else {
        this.emailForm = true;
      }
    },
  },
  mounted() {
    this.getViewingRooms();
  },
};
</script>
