<template>
  <div class="outer-box vertical-space footer">
    <div class="one-third-width inner-space">
      <router-link to="/">
        <img class="logo" src="../assets/logo.png" alt="" />
      </router-link>
    </div>
    <div class="two-thirds-width inner-space">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-4">
          <p>London Gallery:<br />12 Bury Street, St. James’s, London<br />SW1Y 6AB</p>
        </div>
        <div class="cell small-12 medium-4">
          <p>The Studio:<br />Lord’s Wood, Marlow, Bucks<br />SL7 2QS</p>
        </div>
        <div class="cell small-12 medium-4">
          <p>
            <i class="material-symbols-outlined small light-grey">phone</i>+44 (0)20 7287 4448
            <br />
            <i class="material-symbols-outlined small light-grey">email</i>info@messums.com
            <br />
            &copy; Copyright 2022 Messum's
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteFooter',
};
</script>

<style lang="scss">
  .footer {
    padding-bottom: 40px;
  }

 .footer p {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.3;
  padding-top: 17px;
 }

 .material-symbols-outlined.small {
  position:relative;
  top: 1px;
  font-size: 10px;
  display: inline-block;
  padding-right: 3px;
 }
</style>
