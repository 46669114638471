<template>
  <div class="modal">
    <div class="modal-inner">
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12 medium-2">
          <img class="logo" src="../assets/logo.png" alt="Messums" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12 medium-6">
          <h1>{{ room.title }}</h1>
          <p>
            Messum's is pleased to present a viewing room accompanying {{ room.title }} 2022
            exhibition at our St. James gallery.
          </p>
          <label>
            Please enter you email address to access this viewing room.
          </label>
          <input v-model="email" type="email" />
          <label><input v-model="subscribe" type="checkbox" />Receive our newsletters</label>
          <button class="button" @click="enterRoom">Enter viewing room</button>
          <p class="small">
            We will process your personal data in accordance with our
            <a href="https://www.messums.com/privacy" target="_blank">privacy
            policy.</a> You can unsubscribe or change your preferences at any time by clicking the
            link in any emails we send.
          </p>
        </div>
        <div class="cell small-12 medium-6">
          <img :src="room.uri" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'AccessForm',
  props: [
    'room',
  ],
  data() {
    return {
      email: '',
      subscribe: false,
    };
  },
  methods: {
    enterRoom() {
      const postData = {};
      postData.email = this.email;
      postData.subscribe = this.subscribe;
      postData.viewingRoom = this.room.id;
      axios.post('/signUps/enterViewingRoom.json', postData)
        .then(() => {
          this.$store.commit('grantAccess');
          this.$cookies.set('sdlAccess', 'true');
          this.$router.push(`/room/${this.room.id}`);
        });
    },
  },
};
</script>

<style>
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(51, 51, 51, 0.9);
  }

  .modal-inner {
    position: absolute;
    top: 10%;
    right: 10%;
    left: 10%;
    z-index: 3;
    background-color: #FFF;
    border: 1px solid #7c7c7c;
    border-radius: 3px;
    padding-bottom: 30px;
  }
</style>
