import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/room/:id',
    name: 'Room',
    component: () => import('../views/Room.vue'),
    beforeEnter(to, from, next) {
      if (store.state.access) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/roommackrill',
    name: 'RoomMackrill',
    component: () => import('../views/RoomMackrill.vue'),
    beforeEnter(to, from, next) {
      if (store.state.access) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/viewing-room/:id',
    name: 'ViewingRoom',
    component: () => import('../views/ViewingRoom.vue'),
    beforeEnter(to, from, next) {
      if (store.state.access) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:id',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('../views/Start.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/add-room',
    name: 'AddRoom',
    component: () => import('../views/AddRoom.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/add-main-image/:id',
    name: 'AddMainImage',
    component: () => import('../views/AddMainImage.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/edit-room/:id',
    name: 'EditRoom',
    component: () => import('../views/EditRoom.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
