import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import store from './store';
import router from './router';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

Vue.config.productionTip = false;

Vue.use(VueCookies, { expire: '7d' });

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
