<template>
    <div class="outer-box">
      <div class="one-third-width inner-space text-center-small">
        <img class="logo" src="../assets/logo.png" alt="Messum's" />
      </div>
      <div class="two-thirds-width inner-space">
        <ul class="navigation">
          <li><router-link to="/">Viewing Rooms</router-link></li>
          <li><a href="https://www.messums.com/">Website</a></li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss">
  .logo {
    display: inline-block;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .navigation {
    padding-top: 18px;
    float:right;
    li {
      float: left;
      margin-left: 20px;
      font-family: 'quicksand';
      list-style-type: none;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 300;
      padding-bottom: 6px;

      &:hover,
      &:focus {
        border-bottom: 1px solid #7C7C7C;
      }

      a {
        color: #7C7C7C;
      }
    }
  }
  @media all and (max-width:700px) {
    .navigation {
      float: none;
      width: 100%;
      li {
        width: 50%;
        text-align: center;
        margin-left: 0;
      }
    }
  }
</style>
